import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Slick from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HeaderCarousel = () => {
  const settings = {
    fade: true,
    autoplay: true,
    autoplaySpeed: 10000,
    infinite: true,
    arrows: false,
    speed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <ul className="slider lg:h-[95vh] h-[30rem]">
      <Slick {...settings}>
        <li>
          <StaticImage src='../images/Headers/product.jpg' alt='product' placeholder="blurred" className='object-cover lg:h-[95vh] h-[30rem] w-full opacity-50'/>
        </li>
        <li>
          <StaticImage src='../images/Headers/lifestyle.jpg' alt='lifestyle' placeholder="blurred" className='object-cover lg:h-[95vh] h-[30rem] w-full opacity-50'/>
        </li>
        <li>
          <StaticImage src='../images/Headers/entertainment.jpg' alt='entertainment' placeholder="blurred" className='object-cover lg:h-[95vh] h-[30rem] w-full opacity-50'/>
        </li>
        <li>
          <StaticImage src='../images/Headers/news.jpg' alt='news' placeholder="blurred" className='object-cover lg:h-[95vh] h-[30rem] w-full opacity-50'/>
        </li>
        <li>
          <StaticImage src='../images/Headers/outdoor.jpg' alt='outdoor' placeholder="blurred" className='object-cover lg:h-[95vh] h-[30rem] w-full opacity-50'/>
        </li>
      </Slick>
    </ul>
  );
}

export default HeaderCarousel